import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView/index.vue'
import store from '@/store'
const routes = [
  {
    path: '/404',
    component: () => import('../views/NextView/404')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
  {
    path: '/',
    name: '首页',
    component: HomeView,
    meta: {
      IsLog: false
    }
  },
  {
    path: '/checkLine', // 线路选择
    name: 'checkLine',
    component: () => import('../views/HomeView/checkLine'),
    meta: {
      title: '线路选择',
      IsLog: false
    }
  },
  {
    path: '/ins',
    name: '保险理财',
    component: () => import('../views/InsuranceView/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/productBuy',
    name: 'productBuy',
    component: () => import('../views/InsuranceView/productBuy.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/balance',
    name: 'balance',
    component: () => import('../views/InsuranceView/balance.vue'),
    meta: {
      IsLog: true,
      title: '余利宝'
    }
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('../views/LoginView/index.vue'),
    meta: {
      IsLog: false
    }
  },
  {
    path: '/res',
    name: '注册',
    component: () => import('../views/ResView/index.vue'),
    meta: {
      IsLog: false
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/LoginView/forget.vue'),
    meta: {
      IsLog: false
    }
  },
  {
    path: '/order',
    name: '订单列表',
    component: () => import('../views/OrderView/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/user',
    name: '个人中心',
    component: () => import('../views/UserView/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/waybill',
    name: '运单任务',
    component: () => import('../views/WaybillView/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/startBuy',
    name: 'startBuy',
    component: () => import('../views/WaybillView/startBuy.vue'),
    meta: {
      title: '开始下单'
    }
  },
  {
    path: '/buyInfo',
    name: 'buyInfo',
    component: () => import('../views/WaybillView/buyInfo.vue'),
    meta: {
      title: '下单信息'
    }
  },
  {
    path: '/real',
    name: '实名认证',
    component: () => import('../views/NextView/CertificationView/index.vue'),
    meta: {
      IsLog: true,
      title: '实名认证'
    }
  },
  {
    path: '/realStepTow',
    name: '实名认证二',
    component: () => import('../views/NextView/CertificationView/realStepTow.vue')
  },
  {
    path: '/realStepThree',
    name: '实名认证三',
    component: () => import('../views/NextView/CertificationView/realStepThree.vue')
  },
  {
    path: '/integ',
    name: '积分商城',
    component: () => import('../views/NextView/IntegralView/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/shopDetail',
    name: 'shopDetail',
    component: () => import('../views/NextView/IntegralView/shopDetail.vue'),
    meta: {
    }
  },
  {
    path: '/myChange',
    name: 'myChange',
    component: () => import('../views/NextView/IntegralView/myChange.vue'),
    meta: {
      title: '发货信息'
    }
  },
  {
    path: '/msg',
    name: '我的消息',
    component: () => import('../views/NextView/MessageView/index.vue'),
    meta: {
      IsLog: true,
      title: '站内消息'
    }
  },
  {
    path: '/msgDetail',
    name: '消息详情',
    component: () => import('../views/NextView/MessageView/msgDetail.vue')
  },
  {
    path: '/recharge',
    name: '充值',
    component: () => import('../views/NextView/RechargeView/index.vue'),
    meta: {
      title: '充值',
      IsLog: true
    }
  },
  {
    path: '/rechargeTransition',
    name: 'rechargeTransition',
    component: () => import('../views/NextView/RechargeView/transition.vue'),
    meta: {
      title: '充值'
    }
  },
  {
    path: '/rechargeList',
    name: 'rechargeList',
    component: () => import('../views/NextView/RechargeView/list.vue'),
    meta: {
      title: '充值记录'
    }
  },
  // {
  //   path: '/rechargeDetail',
  //   name: 'rechargeDetail',
  //   component: () => import('../views/NextView/RechargeView/detail.vue'),
  //   meta: {
  //     title: '充值详情'
  //   }
  // },
  {
    path: '/withdraw',
    name: '提现',
    component: () => import('../views/NextView/WithdrawView/index.vue'),
    meta: {
      title: '提现'
    }
  },
  {
    path: '/withdrawList',
    name: 'withdrawList',
    component: () => import('../views/NextView/WithdrawView/list.vue'),
    meta: {
      title: '提现记录',
      IsLog: true
    }
  },
  {
    path: '/team',
    name: '团队报表',
    component: () => import('../views/NextView/TeamView/index.vue'),
    meta: {
      title: '团队报表'
    }
  },
  {
    path: '/teamSet',
    name: 'teamSet',
    component: () => import('../views/NextView/TeamView/setting.vue'),
    meta: {
      title: '团队设置'
    }
  },
  {
    path: '/teamMore',
    name: 'teamMore',
    component: () => import('../views/NextView/TeamView/more.vue'),
    meta: {
      title: '团队收益'
    }
  },
  {
    path: '/teamSearch',
    name: 'teamSearch',
    component: () => import('../views/NextView/TeamView/search.vue'),
    meta: {
      title: '直属推荐查询',
      IsLog: true
    }
  },
  {
    path: '/teamList',
    name: 'teamList',
    component: () => import('../views/NextView/TeamView/teamList.vue'),
    meta: {
      title: '团队列表',
      IsLog: true
    }
  },
  {
    path: '/teamTree',
    name: 'teamTree',
    component: () => import('../views/NextView/TeamView/teamTree.vue'),
    meta: {
      title: '关系树',
      IsLog: true
    }
  },
  {
    path: '/account',
    name: '账户明细',
    component: () => import('../views/NextView/AccountView/index.vue'),
    meta: {
      title: '账单记录',
      IsLog: true
    }
  },
  {
    path: '/insbuy',
    name: '保险购买',
    component: () => import('../views/NextView/InsureBuy/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/insdetail',
    name: '订单详情',
    component: () => import('../views/NextView/InsureDetail/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/insuranceList',
    name: 'insuranceList',
    component: () => import('../views/InsuranceView/list'),
    meta: {
      IsLog: true,
      title: '保险列表'
    }
  },
  {
    path: '/manage',
    name: '理财详情',
    component: () => import('../views/NextView/ManageDetail/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/inslist',
    name: '保单详情',
    component: () => import('../views/NextView/InsureList/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/managelist',
    name: '理财收益',
    component: () => import('../views/NextView/ManageList/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('../views/NextView/ManageList/inquiryIndex.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/orfinish',
    name: '订单结算',
    component: () => import('../views/NextView/OrderFinish/index.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/choujiangList',
    name: '抽奖记录',
    component: () => import('../views/UserView/choujiangList.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/renwuList',
    name: '任务记录',
    component: () => import('../views/UserView/renwuList.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/info',
    name: '个人信息',
    component: () => import('../views/UserView/info.vue'),
    meta: {
      IsLog: true
    }
  },
  {
    path: '/editMyInfo',
    name: '修改个人信息',
    component: () => import('../views/UserView/editMyInfo.vue'),
    meta: {
      IsLog: true,
      title: '修改个人信息'
    }
  },
  {
    path: '/addWalletList',
    name: 'addWalletList',
    component: () => import('../views/UserView/addWalletList.vue'),
    meta: {
      IsLog: true,
      title: '添加钱包地址'
    }
  },
  {
    path: '/addWallet',
    name: 'addWallet',
    component: () => import('../views/UserView/addWallet.vue'),
    meta: {
      IsLog: true,
      title: '添加钱包地址'
    }
  },
  {
    path: '/editLoginPwd',
    name: 'editLoginPwd',
    component: () => import('../views/UserView/editLoginPwd.vue'),
    meta: {
      IsLog: true,
      title: '修改登录密码'
    }
  },
  {
    path: '/editPayPwd',
    name: 'editPayPwd',
    component: () => import('../views/UserView/editPayPwd.vue'),
    meta: {
      IsLog: true,
      title: '修改支付密码'
    }
  },
  {
    path: '/feedbook',
    name: 'feedbook',
    component: () => import('../views/NextView/FeedbackView/index'),
    meta: {
      IsLog: true,
      title: '意见反馈'
    }
  },
  {
    path: '/framePage',
    name: 'framePage',
    component: () => import('../components/iframePage/index'),
    meta: {
      IsLog: true,
      title: '购买Vip'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (store.state.token) {
    if (to.path !== '/login' && to.path !== '/res') {
      next()
    } else {
      router.go(-1)
    }
  } else {
    if (to.path === '/login' || to.path === '/res' || !to.meta.IsLog) {
      next()
    } else {
      router.push('/login')
    }
  }
})
export default router
