import "core-js/modules/es.array.push.js";
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getGoods, orderTaskBuy, orderTaskSetting, orderTask } from "@/assets/js/api";
import { showFailToast, showSuccessToast, showToast } from "vant";
export default {
  name: "startBuy",
  inject: ["$back"],
  props: ["taskName", "type", "platform"],
  setup(props) {
    console.log(props);
    const {
      query
    } = useRoute();
    const {
      push
    } = useRouter();
    const {
      go
    } = useRouter();
    const data = reactive({
      tobottom: null,
      themeVars: {
        radioDisabledLabelColor: "#5A5B5E",
        fieldDisabledTextColor: "#5A5B5E"
      },
      task_name: props.taskName,
      buyType: props.type,
      showPopover: false,
      actions: [{
        text: "选项一"
      }, {
        text: "选项二"
      }, {
        text: "选项三"
      }],
      goodsInfo: [],
      goodsType: [],
      goodsMinTime: 0,
      goodsMaxTime: 0,
      shopMinTime: 0,
      shopMaxTime: 0,
      companyNum: 0,
      companyMinTime: 0,
      companyMaxTime: 0,
      buyMoneyMin: 0,
      buyMoneyMax: 0,
      maxSellNum: 0,
      orderType: 0,
      collect: [],
      sku: 0,
      platform: props.platform,
      addressList: [],
      customFieldName: {
        text: "address",
        value: "id"
      },
      showAddress: false,
      reCheck: "",
      buySuccess: false,
      buyResList: [],
      runningRes: [],
      resCont: [],
      istrue: false,
      alld: 0,
      nowGood: "",
      timeOut: "",
      buyTimes: 1,
      order_times: 10,
      fortimes: 1,
      stop_flag: false,
      current_click: 1,
      orderList: [],
      checked: ""
    }); // 数据
    const methods = {
      checkRecharge({
        selectedOptions
      }) {
        data.reCheck = selectedOptions[0];
        data.showAddress = false;
      },
      async onload() {
        const res = await orderTaskSetting({
          task_name: data.task_name
        });
        if (res.code === 200) {
          data.goodsInfo = res.data;
          data.goodsType.push(res.data.type);
          data.goodsMinTime = res.data.goods_mini;
          data.goodsMaxTime = res.data.goods_max;
          data.shopMinTime = res.data.shop_mini;
          data.shopMaxTime = res.data.shop_max;
          data.companyNum = res.data.compare_num;
          data.companyMinTime = res.data.compare_mini;
          data.companyMaxTime = res.data.compare_max;
          data.buyMoneyMin = res.data.mini_price;
          data.buyMoneyMax = res.data.max_price;
          data.maxSellNum = res.data.max_sell_num;
          data.orderType = res.data.order_type;
          data.collect.push(res.data.collect);
          data.sku = res.data.sku;
          data.addressList = res.data.address;
        }
        const orderRes = await orderTask({
          page: 1,
          pages_size: 10
        });
        if (orderRes.code === 200) {
          if (orderRes.data.length !== 0) {
            data.checked = 0;
            data.orderList = orderRes.data;
          }
          data.orderList.forEach((element, index) => {
            if (data.task_name == element.text) {
              data.checked = index;
            }
          });
        }
      },
      async buyRun(t) {
        if (!data.task_name) {
          showFailToast("错误，请重新进入");
          return;
        } else if (!data.reCheck.address) {
          showFailToast("请先选择收货地址后再操作！");
          return;
        } else if (data.buyType == "undefined") {
          showFailToast("类型错误，请重新进入");
          return;
        }
        data.buySuccess = true;
        data.stop_flag = true;
        data.runningRes = [];
        data.fortimes = 0;
        data.buyTimes = 1;
        data.current_click++;
        t = data.buyType == 1 ? data.order_times : t;
        methods.runningFun(t, data.current_click);
      },
      async buyIt() {
        let res = await orderTaskBuy({
          task_name: data.task_name,
          address: data.reCheck.address,
          id: data.nowGood.id,
          auto: data.buyType
        });
        if (res.code === 200) {
          data.runningRes.push("支付成功，已购买" + data.nowGood.title);
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        } else {
          data.runningRes.push("支付失败");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
          showToast(res.message);
          data.stop_flag = false;
          data.buySuccess = false;
          setTimeout(function () {
            methods.closeRun();
          }, 1500);
        }
      },
      async getGood() {
        let getGoodsRes = await getGoods({
          platform: data.platform
        });
        if (getGoodsRes.code === 200 && getGoodsRes.data !== null) {
          data.nowGood = getGoodsRes.data;
        } else {
          data.runningRes.push("出错了，未获取到商品");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
          showToast("出错了，未获取到商品，请重试");
          data.stop_flag = false;
          data.buySuccess = false;
          setTimeout(function () {
            methods.closeRun();
          }, 1500);
        }
      },
      async runfunc(current_click) {
        if (!data.stop_flag) return;
        if (data.buyTimes > 1 && data.buyTimes % 2 == 1) {
          if (data.orderList.length > data.checked) {
            data.checked = data.checked + 1;
            data.task_name = data.orderList[data.checked].text;
          }
          data.runningRes.push("账号自动切换为：" + data.task_name);
        }
        let step = 500;
        data.runningRes.push("正在开始第" + data.buyTimes + "个订单，账号：" + data.task_name);
        data.buyTimes = data.buyTimes + 1;
        setTimeout(function () {
          data.tobottom.scrollTop = data.tobottom.scrollHeight;
        }, 50);
        methods.getGood();
        setTimeout(function () {
          console.log("current_click", current_click);
          console.log("data.current_click", data.current_click);
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在筛选商品中");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在浏览商品1秒");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 2);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在货比3家");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 3);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在收藏商品");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 4);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("选中" + data.nowGood.title);
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 5);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在下单");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 6);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("生成订单中...");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 7);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在检测支付通道是否通畅");
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 8);
        setTimeout(function () {
          if (!data.stop_flag || current_click < data.current_click) return;
          data.runningRes.push("正在支付中");
          methods.buyIt();
          setTimeout(function () {
            data.tobottom.scrollTop = data.tobottom.scrollHeight;
          }, 50);
        }, step * 9);
      },
      runningFun(times, current_click) {
        let step = 500;
        if (data.stop_flag && data.fortimes < times) {
          methods.runfunc(current_click);
          data.fortimes++;
          setTimeout(function () {
            methods.runningFun(times);
          }, step * 13);
        } else {
          showToast("运单任务执行完成");
          setTimeout(function () {
            push("/order");
          }, 1500);
        }
      },
      closeRun() {
        data.stop_flag = false;
        data.buySuccess = false;

        // this.$emit("closexiadan", "Data from child");
      }
    }; // 事件
    methods.onload();
    return {
      ...toRefs(data),
      ...methods
    };
  }
};