import "core-js/modules/es.array.push.js";
import { imgDetails, configFile, signGoodsList, orderTask, getVerifyCode, toVerifyCode, refreshTask, setZhuli, getZhuli, getMemberInfo } from "@/assets/js/api";
import { imgFilter, jumpPage, servis, filterStopTime } from "@/assets/js/index";
import { reactive, toRefs, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import verifyCode from "@/components/verifyCode";
import { showFailToast, showSuccessToast } from "vant";
import startBuy from "@/views/WaybillView/buyInfo_pop";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",
  inject: ["$back", "$imgFilter"],
  components: {
    verifyCode,
    startBuy
  },
  setup() {
    const {
      state,
      dispatch,
      commit
    } = useStore();
    dispatch("ACTgetMyinfo");
    const {
      push
    } = useRouter();
    const data = reactive({
      isLog: computed(e => state.token),
      rechargeData: {
        name: "充值",
        ico: require("@/assets/img/gird/1.png"),
        path: "/recharge"
      },
      grid: [{
        name: "提现",
        ico: require("@/assets/img/gird/2.png"),
        path: "/withdraw"
      }, {
        name: "团队报表",
        ico: require("@/assets/img/gird/3.png"),
        path: "/team"
      }, {
        name: "账户明细",
        ico: require("@/assets/img/gird/4.png"),
        path: "/account"
      }, {
        name: "个人信息",
        ico: require("@/assets/img/gird/5.png"),
        path: "/info"
      }, {
        name: "积分商城",
        ico: require("@/assets/img/gird/6.png"),
        path: "/integ"
      }, {
        name: "我的消息",
        ico: require("@/assets/img/gird/7.png"),
        path: "/msg"
      }, {
        name: "联系客服",
        ico: require("@/assets/img/gird/8.png"),
        path: "server"
      }],
      isReady: false,
      show: false,
      lottery_switch: "",
      signMsg: "",
      banner: [],
      marquee_placard: "",
      pop_ups_placard: "",
      service: "",
      // hide_inside_switch: "",
      // token: sessionStorage.getItem('token'),
      token: localStorage.getItem("token"),
      myInfo: computed(() => state.info),
      isOpen: localStorage.getItem("is_open"),
      list: [],
      loading: false,
      finished: false,
      page: 0,
      orderList: [],
      checked: "",
      showCenter: false,
      showCenterPlatform: false,
      showZhuli: false,
      showZhuli2: false,
      getZhuli: false,
      curIdentifyCode: "",
      buyType: "",
      verifyCode: "",
      platform: 0,
      chongzhi_flag: false,
      xiadan_flag: false
    }); // 数据
    const methods = {
      gorecharge(index) {
        if (index) {
          let type = index - 1;
          push("/recharge?t=" + type);
          data.chongzhi_flag = false;
        }
        data.chongzhi_flag = true;
      },
      commit,
      servis,
      push,
      toServis(path) {
        if (path === "server") {
          servis();
        } else {
          push(path);
        }
      },
      toJump(val, type) {
        if (val) {
          if (type === "1") {
            jumpPage(val);
          } else if (type === "0") {
            push(val);
          }
        }
      },
      getImg(img) {
        return imgFilter(img);
      },
      async load() {
        let ress = await getMemberInfo();
        if (ress.code === 200) {
          if (ress.data.zhuli_status == 1) {
            data.showZhuli2 = true;
          }
        }
        let ress2 = await getZhuli();
        if (ress2.code === 200) {
          if (ress2.data.flag) {
            data.getZhuli = true;
          }
        }
        const banner = await imgDetails({
          pid: "1"
        });
        if (banner.code === 200) {
          data.banner = banner.data;
        }
        const res = await configFile({
          field: ["marquee_placard", "pop_ups_placard", "pop_ups_placard_switch", "customer_service_link", "lottery_switch"
          // "hide_inside_switch"
          ]
        });
        if (res.code === 200) {
          data.marquee_placard = res.data.marquee_placard;
          data.pop_ups_placard = res.data.pop_ups_placard;
          data.service = res.data.customer_service_link;
          data.lottery_switch = res.data.lottery_switch;
        }
        const list = await signGoodsList({
          page: data.page += 1,
          lang: "cn"
        });
        if (list.code === 200) {
          // data.loading = false
          // this.signList = list.data;
          data.list.push.apply(data.list, list.data);
        }
        // 数据全部加载完成
        // if (list.data.length < 10) {
        //   data.finished = true
        // }

        const orderRes = await orderTask({
          page: 1,
          pages_size: 10
        });
        if (orderRes.code === 200) {
          if (orderRes.data.length !== 0) {
            data.checked = orderRes.data[0].text;
            data.orderList = orderRes.data;
          }
        }
      },
      filterStopTime,
      async reload() {
        const res = await refreshTask();
        if (res.code === 200) {
          showSuccessToast("刷新成功");
          data.page = 0;
          data.checked = "";
          data.list = [];
          // methods.onLoad()
          methods.load();
        }
      },
      async checkPlatform() {
        data.showCenterPlatform = false;
        methods.getCode();
      },
      async getCode(type) {
        data.showCenter = true;
        const res = await getVerifyCode();
        if (res.code === 200) {
          data.curIdentifyCode = res.data;
        }
      },
      saveType(type) {
        if (methods.filterStopTime(data.myInfo.vip_stop_time) === 0) {
          commit("UPDATEADDVIP", true);
        } else {
          // if (type === 1) {
          //   showFailToast("无权限");
          //   return;
          // }
          data.buyType = type;
          // data.showCenterPlatform = true
          methods.getCode();
        }
      },
      closezhuli() {
        data.showZhuli = false;
      },
      gochoujiang() {
        let url = "/ins";
        push(url);
      },
      gozhuli() {
        setZhuli({});
        data.showZhuli2 = false;
        setTimeout(function () {
          data.showZhuli = true;
        }, 500);
      },
      closegetzhuli() {
        data.getZhuli = false;
        let url = "/choujiangList";
        push(url);
      },
      closexiadan() {
        data.xiadan_flag = false;
      },
      async buyOrderFun() {
        // if (methods.filterStopTime(data.myInfo.vip_stop_time) === 0) {
        //   commit('UPDATEADDVIP', true)
        // } else {
        const res = await toVerifyCode({
          code: data.verifyCode
        });
        if (res.code === 200) {
          data.showCenter = false;
          data.xiadan_flag = true;
          return;
          push({
            path: "/buyInfo",
            query: {
              taskName: data.checked,
              type: data.buyType,
              platform: data.platform
            }
          });
        } else {
          methods.getCode();
        }
        // }
      }
    }; // 事件
    onMounted(() => {
      methods.load();
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};