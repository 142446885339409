import "core-js/modules/es.array.push.js";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { buyVip, configFile } from "@/assets/js/api";
import { showFailToast, showToast } from "vant";
import { jumpPage } from "@/assets/js";
import { useRouter } from "vue-router";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "index",
  inject: ["$imgFilter", "$servis"],
  setup() {
    const {
      state,
      commit
    } = useStore();
    const {
      push
    } = useRouter();
    const data = reactive({
      show: false,
      active: "1",
      index: 1,
      type: 1,
      buyMoney: "",
      aliQr: "",
      wecQr: "",
      vipMethod: "",
      vipMoney: "1"
    });
    const methods = {
      closePop() {
        data.type = 1;
        data.show = false;
      },
      saveImg(Url) {
        const a = document.createElement("a");
        const event = new MouseEvent("click");
        a.download = "支付二维码";
        a.href = Url;
        a.dispatchEvent(event);
      },
      submit() {},
      async buyOrder() {
        // let buyType = ''
        // if (data.active === 1) {
        //   buyType = 1
        // }
        if (data.active != 1) {
          showToast("正在跳转支付...");
        }
        const res = await buyVip({
          type: data.active
        });
        if (res.code === 200) {
          if (data.active == 1) {
            showToast("购买成功");
            setTimeout(function () {
              push("/");
            }, 1500);
            return;
          }
          console.log(res.data);
          // data.type = 3
          jumpPage(res.data);
          // data.show = false;
          // push({ path: '/framePage', query: { website: res.data } })
        }
      },
      async onload() {
        data.type = 1;
        const res = await configFile({
          field: ["vip_money",
          // VIP充值金额
          "vip_method" // VIP充值方式
          ]
        });
        if (res.code === 200) {
          data.buyMoney = res.data.vip_money;
          data.vipMethod = res.data.vip_method;
        }
      }
    };
    // methods.onload()
    watch(() => state.addvip, a => {
      data.show = a;
    });
    watch(() => state.addvipType, a => {
      data.type = a;
    });
    watch(() => data.show, a => {
      if (a === true) {
        methods.onload();
      }
      commit("UPDATEADDVIP", a);
    });
    return {
      ...toRefs(data),
      ...methods
    };
  }
};